export default {
  "form_2": {
    "kedokteran_disabilitas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant with disabilities and/or color blindness cannot apply to this program."])},
    "simulasi_biaya": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cost Simulation"])},
    "not_include_contribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Including Contribution Fund"])}
  },
  "payment_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment successfully created! Retrieving Virtual Account."])},
  "login_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome Aboard!"])},
  "login_header_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is your PCU Admission page"])},
  "login_notice_browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please open the registration page using Chrome / Mozilla Firefox."])},
  "welcome_home_petranesian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advance your professional life."])},
  "login_anda_stay_connected_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in to get check on your application portal"])},
  "grow_Professionally_advance_your_professional_life_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grow professionally/Advance your professional life Build a career-boosting network with fellow alumni and future employers, right here at Petranesian Alumni Platform."])},
  "welcome_back_to_passion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recruit Petra Alumni for Your Company"])},
  "log_id_access_our_full_features_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the blanks below to begin your amazing journey with PCU"])},
  "discover_top_talets_from_our_alumni_database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover Top Talents From Our Alumni Database"])},
  "join_PANNEL_to_hire_our_best_graduates_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join Petranesian Alumni Platform to hire our best graduates to boost your company growth"])},
  "company_login_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome back to PANNEL"])},
  "company_login_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in to access our full features, from posting vacancies to hiring our top graduates"])},
  "company_register_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover Top Talents From Our Alumni Database"])},
  "company_register_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join PANNEL to hire our best graduates to boost your company growth"])},
  "frontend": {
    "header": {
      "whatsuppetra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's Up Petra"])}
    },
    "footer": {
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "contactinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Information"])},
      "heading": {
        "admission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admissions"])},
        "tentang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petra at a Glance"])},
        "hubungi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
        "fasilitas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilites"])},
        "fakultas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic"])}
      }
    },
    "submenus": {
      "carapendaftaran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to Apply"])},
      "beasiswa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scholarships"])},
      "halamanpac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login PAC"])},
      "informasites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admission Test"])},
      "infokeuangan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuition and Fees"])},
      "simulasirapor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transcript Simulation"])},
      "pengumumanpenerimaan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admission Results"])},
      "pascasarjana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postgraduate Program"])},
      "tesminatbakat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potential Quiz"])},
      "mengapapetra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why Petra"])},
      "tinggalsurabaya": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Living in Surabaya"])},
      "campuslife": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campus Life"])},
      "facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilities"])},
      "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities"])},
      "tanggalpenting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important Dates"])},
      "beritaacara": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
      "alumni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alumni"])},
      "perpus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library"])},
      "klinik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinic"])},
      "konseling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Counseling Center"])},
      "puskom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Computer Center"])},
      "careercenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Career Center"])},
      "kemahasiswaan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Center"])},
      "internationaloffice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["International Office"])},
      "happening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Happenings"])},
      "highlights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highlights"])},
      "pressrelease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcements"])},
      "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archives"])},
      "programinterheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["International Programs"])},
      "pascaheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postgraduate / Profession"])},
      "msipil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master's Program in Civil Engineering"])},
      "marsitek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master's Program in Architecture"])},
      "msastra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master's Program in Literature"])},
      "mteknik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master's Program in Industrial Engineering"])},
      "mmanajemen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master's Program in Management"])},
      "doktorsipil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctoral Program in Civil Engineering"])},
      "pendidikanprofesiarsitek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Architect Profession Education Program"])},
      "pendidikanprofesiinsinyur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engineer Profession Education Department"])},
      "dmu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Education Department"])}
    }
  },
  "menu": {
    "alumni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alumni"])},
    "tracer_study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracer Study"])},
    "find_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Jobs"])},
    "find_talents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Talents"])},
    "find_talents_submenu": {
      "inside_image": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petranesian Alumni"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our vast graduate database helps you discover and bring top talents into your organization—from IT, creative, finance, to engineering. "])}
      },
      "submenu_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petranesian Alumni"])},
      "submenu_list": {
        "alumni_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alumni Service"])},
        "alumni_business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alumni Business"])},
        "alumni_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alumni Product"])}
      }
    },
    "alumni_relations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alumni Relations"])},
    "alumni_relations_submenu": {
      "give_back": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give Back Program"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support Indonesia’s future leaders through Petra Christian University"])}
      },
      "alumni_stories": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alumni Stories"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dive into our alumni’s career journeys, stellar achievements, and captivating love stories."])}
      }
    },
    "merchandise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchandise"])},
    "digital-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petranesian Alumni Card"])},
    "newsinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News & Information"])},
    "sign_in_sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login / Register"])},
    "sign_in_sign_up_submenu": {
      "alumni": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Alumni"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay connected. Keep your profile updated to get the most out of PANNEL."])}
      },
      "company": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Companies"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join PANNEL to hire our best graduates to boost your company growth."])}
      }
    },
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "prospectivestudentdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prospective Student Data"])},
    "admissionprocess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admission On Process"])},
    "schoolfiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Files"])},
    "averagereport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Report Score"])},
    "studyprogram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program Options"])},
    "sign_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Out"])},
    "mobile": {
      "view_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Card"])},
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
      "account_submenu": {
        "view_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Card"])},
        "edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
        "view_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Profile"])},
        "education_activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education & Activities"])},
        "work_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Experience"])},
        "your_business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Business"])},
        "your_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Products"])},
        "applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications"])},
        "bookmarked_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookmarked Jobs"])},
        "item_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Orders"])},
        "your_vacancies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Vacancies"])}
      }
    }
  },
  "tracerstudy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Feedback Makes Us Better"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help us sustain years of excellence with a short survey"])}
  },
  "logout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Will Exit The App"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue?"])}
  },
  "form1": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure to submit this form?"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The data that has been entered cannot be changed. (except: WA, Line, Instagram)"])}
  },
  "forgot_password": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password"])},
    "subtitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email, and an OTP code will be sent via email."])},
    "subtitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter OTP code that has been sent via email."])},
    "subtitle3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a new password for your account."])},
    "trouble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouble with your Account? Contact us "])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "step1": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
    },
    "step2": {
      "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP Code"])},
      "placeholderotp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter OTP code"])}
    },
    "step3": {
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "password_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
      "placeholderpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter password"])},
      "placeholdercpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter confirm password"])}
    },
    "success": {
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password has been changed successfully."])}
    }
  },
  "login": {
    "placeholderemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter email"])},
    "placeholderpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter password"])},
    "placeholdername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter full name"])},
    "fullname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
    "termofconditionhead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By creating an account, you agree to the"])},
    "termofconditionbody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and Conditions"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "choose_alumni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Alumni"])},
    "choose_alumni_based_on_lecture_period_and_major_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Alumni Based On Lecture Period and Major First!"])},
    "choose_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select City"])},
    "choose_prody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Study Program"])},
    "choose_province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Province"])},
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue?"])},
    "data_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Not Found, Please Try Again."])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "birth_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth Date"])},
    "birth_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth Place"])},
    "graduation_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graduation Year"])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here"])},
    "insert_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Address"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "nim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NIM"])},
    "forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password?"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "password_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
    "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
    "please_insert_a_name_of_at_least_3_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please insert your name with minimum 3 characters"])},
    "sign_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "trouble_register_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouble register in? Contact us"])},
    "trouble_signin_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouble logging in? Contact us"])},
    "wait_for_a_moment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait for a moment"])},
    "year_of_entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year of Entry"])},
    "you_cant_sign_in_with_admin_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can't Login with admin account"])},
    "you_will_do_the_registration_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Will Do The Registration Process"])},
    "company": {
      "register_part1": {
        "error_messages": {
          "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name is required"])},
          "bussiness_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bussiness field is required"])},
          "bussiness_scope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bussiness scope is required"])},
          "telp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company phone number is required"])},
          "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City and province is required"])},
          "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address is required"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description is required"])}
        },
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name *"])},
        "id_mh_bussines_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Business Field *"])},
        "bussiness_scope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Business Scope *"])},
        "bussiness_scope_item": {
          "local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local"])},
          "national": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["National"])},
          "multinational": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multinational / International"])}
        },
        "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
        "telp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Office Phone Number *"])},
        "id_mh_province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Province *"])},
        "id_mh_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select City *"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address *"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Logo"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Description *"])}
      },
      "register_part2": {
        "contact_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Person *"])},
        "contact_person_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Person Position *"])},
        "contact_person_telp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Person Telephone *"])}
      },
      "register_part3": {
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email *"])},
        "email_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use official company email and not personal email"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password *"])},
        "password_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password *"])},
        "password_confirm_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password confirmation does not match"])}
      },
      "register_success": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully registered, <br />Please wait for the approval process from the Admin."])}
      }
    }
  },
  "main": {
    "alumni": {
      "alumni_profile_is_not_open_to_companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alumni profile is not open to companies!!"])},
      "work_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Status"])},
      "work_status_options": {
        "employed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employed"])},
        "unemployed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open To Work"])}
      },
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted"])},
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
      "activity_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity History"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
      "agency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agency"])},
      "all_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Status"])},
      "application_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application History"])},
      "apply_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Filter"])},
      "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved"])},
      "bookmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookmark Vacancy"])},
      "bookmarked_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookmarked Successfully"])},
      "choose_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select city"])},
      "choose_city_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select province first"])},
      "choose_province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select province"])},
      "choose_prody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Program"])},
      "choose_skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Skill"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "current_sallary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Salary"])},
      "view_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Profile"])},
      "edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit profile"])},
      "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
      "explore_petranesian_top_talents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover Top Candidates for Your Company"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find the talent you’ve been looking for."])},
      "farthest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farthest"])},
      "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
      "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
      "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Kelamin"])},
      "get_yours_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get yours today!"])},
      "graduation_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graduation Year"])},
      "select_graduation_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Graduation Year"])},
      "prioritize_unemployee_alumni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioritize Open to Work"])},
      "last_education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Education"])},
      "latest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest"])},
      "list_alumni_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Alumni Product"])},
      "load_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load More"])},
      "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "no_activity_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Activity History"])},
      "no_alumni_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Alumni Found :("])},
      "no_education_history_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Education History Added"])},
      "no_front_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Front Title"])},
      "gpa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GPA"])},
      "no_organizational_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Organizational Experience"])},
      "no_product_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Product Added"])},
      "no_title_behind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Title Behind"])},
      "no_work_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Work Experience"])},
      "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now"])},
      "open_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Card"])},
      "organizational": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizational"])},
      "organizational_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizational Experince"])},
      "petranesian_digital_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petranesian Alumni Card"])},
      "point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point"])},
      "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
      "professional_skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional Skills"])},
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
      "prody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Program"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])},
      "seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seen"])},
      "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
      "show_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Filters"])},
      "since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Since"])},
      "skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skill"])},
      "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort"])},
      "start_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Now"])},
      "there_are_no_bookmark_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no bookmark yet. Let's apply for a job more diligently :-)"])},
      "there_are_no_job_applications_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no job applications yet. Let's apply for a job more diligently :-)"])},
      "till": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["till"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "unreal_works_made_by_petranesian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unreal works made by Petranesians"])},
      "vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacancy"])},
      "waiting_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting For Confirmation"])},
      "we_are_happy_to_know_your_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are happy to know your feedback"])},
      "work_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Experience"])}
    },
    "company": {
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
      "apply_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Filter"])},
      "choose_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select city"])},
      "choose_province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Province"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "company_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Size"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
      "edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit profile"])},
      "my_vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelola Lowongan Pekerjaan"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "find_top_company_around_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Top Company Around You"])},
      "founded_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Founded In"])},
      "hiring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiring"])},
      "industry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industry"])},
      "is_hiring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Hiring"])},
      "job_vacancies_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Vacancy At"])},
      "latest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest"])},
      "looking_for_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Looking for work? here is the right place"])},
      "more_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Filters"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "no_company_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Company Result"])},
      "no_vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Vacancy"])},
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
      "show_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Filter"])},
      "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort"])},
      "till": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["till"])},
      "visit_website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit Website"])}
    },
    "stage": {
      "do_you_want_to_continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to continue?"])},
      "select_muts_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Caps Size"])},
      "muts_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caps Size"])},
      "select_jacket_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Jacket Size"])},
      "jacket_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jacket Size"])},
      "print_definitive_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print Definitive File"])},
      "additional_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Information"])},
      "login_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Portal Login Information"])},
      "principal_registration_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NRP"])},
      "selection_exam_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selection Exam Number"])},
      "financial_admisitration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Administration"])},
      "detail_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Payment"])},
      "semester_payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semester Payments"])},
      "course_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couse Payment"])},
      "total_first_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Payment Total"])},
      "select_voucher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select or Enter voucher code"])},
      "can_pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment can be made "])},
      "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here"])},
      "upload_statement_letter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Statement Letter (* Only authentic documents accepted)"])},
      "upload_birth_certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Birth Certificate (* Only authentic documents accepted)"])},
      "upload_family_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Family Card (* Only authentic documents accepted)"])},
      "company_hp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Phone Number"])},
      "company_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Address"])},
      "ward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Village"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
      "home_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Address (Max 100 Chars)"])},
      "parent_hp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent Phone Number"])},
      "parent_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent Email"])},
      "mother_citizenship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mother Citizenship"])},
      "mother_religion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mother Religion"])},
      "mother_education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mother Education"])},
      "mother_occupation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mother Occupation"])},
      "mother_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mother Name"])},
      "father_citizenship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Father Citizenship"])},
      "father_religion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Father Religion"])},
      "father_education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Father Education"])},
      "father_occupation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Father Occupation"])},
      "father_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Father Name"])},
      "blood_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blood Type"])},
      "order_come": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth Order"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
      "download_template_surat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the statement letter template here"])},
      "family_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family Data"])},
      "apply_other_study_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Another Study Program"])},
      "change_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Study Program"])},
      "reject_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject Offer"])},
      "accept_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept Offer"])},
      "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
      "track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Track"])},
      "study_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Program"])},
      "failed_verbiage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are declared not to have passed the study program:"])},
      "congratulation_verbiage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations, you have passed the study program:"])},
      "general_path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Path"])},
      "achievement_path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achievement Path"])},
      "registration_path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Path"])},
      "school_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School From"])},
      "register_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Number"])},
      "verbiage4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The admissions team is in the process of verifying and evaluating your application. Access the page regularly to get the latest updates"])},
      "application_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application submitted"])},
      "selection_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selection Process"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
      "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minutes"])},
      "regisvalidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Validation"])},
      "schollorigin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Origin"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class"])},
      "placeholdername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter full name"])},
      "placeholderemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter email"])},
      "fullname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name (Based on Birth Certificate)"])},
      "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["District/City of Origin of The School"])},
      "placeholderdistrict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the district/city of origin of the school"])},
      "searchdistrict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type to search district/city of origin of the school"])},
      "searchcountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type to search country"])},
      "searchprovince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type to search province"])},
      "searchcity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type to search city"])},
      "searchreligion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type to search religion"])},
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Origin"])},
      "placeholderschool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select school name"])},
      "searchschool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type to search school name"])},
      "major": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Major"])},
      "placeholdermajor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select major"])},
      "yeargrad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graduation Year"])},
      "promo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation Code (if any)"])},
      "placeholderpromo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter invitation code"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "placeholdercountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose country"])},
      "addressktp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address According to KTP / KK"])},
      "placeholderaddressktp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter address according to KTP / KK"])},
      "addresspaspor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address According to Paspor"])},
      "placeholderaddresspaspor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter address according to Paspor"])},
      "nopassport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport Number"])},
      "homeaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Address"])},
      "placeholderhomeaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "subdistrict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subdistrict"])},
      "placeholdersubdistrict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter subdistrict"])},
      "urbanvillage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urban Village"])},
      "placeholderurbanvillage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter urban village"])},
      "postalcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Code"])},
      "placeholderpostalcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter postal code"])},
      "connectingaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Domicile Address (boarding house / house if any)"])},
      "placeholderconnectingaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter current domicile address"])},
      "addressbackup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fill in the address in Surabaya and its surroundings"])},
      "gereja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Church fullname"])},
      "placeholdergereja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter church"])},
      "gerejanote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filled in for Christians and Catholics"])},
      "nisn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["National Student Registration Number (NISN)"])},
      "placeholdernisn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter NISN"])},
      "curriculum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curriculum"])},
      "placeholdercurriculum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select curriculum"])},
      "uploadreport1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Report X Sem. 1"])},
      "successupload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully Uploaded: "])},
      "report1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report X Sem. 1"])},
      "uploadreport2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Report X Sem. 2"])},
      "report2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report X Sem. 2"])},
      "uploadreport3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Report XI Sem. 1"])},
      "report3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report XI Sem. 1"])},
      "uploadreport4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Report XI Sem. 2"])},
      "report4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report XI Sem. 1"])},
      "uploadreport5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Report XII Sem. 1"])},
      "report5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report XII Sem. 1"])},
      "testschedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Schedule"])},
      "placeholdertestschedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select test schedule"])},
      "choicestudyprogram1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choice of Study Program 1"])},
      "placeholderchoicestudyprogram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select study program"])},
      "studytrackoptions1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Track Options 1"])},
      "placeholderstudytrackoption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select study track"])},
      "choicestudyprogram2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choice of Study Program 2"])},
      "studytrackoptions2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Track Options 2"])},
      "uploadnotcolorblind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Not Color Blind Certificate"])},
      "notcolorblind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Color Blind Certificate"])},
      "difabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special Needs Data (Disabilities)"])},
      "infoscholarship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here to get information about scholarships (tidak berlaku untuk jurusan kedokteran)."])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
      "Link To Check NISN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link To Check NISN"])}
    },
    "talent": {
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
      "agency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agency"])},
      "apply_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Filter"])},
      "choose_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select City"])},
      "choose_province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Province"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
      "contact_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Information"])},
      "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee"])},
      "find_top_service_around_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Petra Alumni Goods & Services"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From the F&B, fashion, to tech industry, explore products from Petranesian alumni"])},
      "freelance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelance"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
      "looking_for_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From the F&B, fashion, to tech industry, explore products from Petranesian alumni"])},
      "no_service_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Service Found"])},
      "profile_alumni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Alumni"])},
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
      "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
      "show_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Filter"])}
    },
    "home": {
      "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
      "track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admission Track"])},
      "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
      "stage1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage 1"])},
      "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Fee Payments"])},
      "textpayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete the payment immediately to continue the admission process, if you already did please refresh the page in 2-3 mins while waiting our system validating the payment, you can go to payment page by pressing"])},
      "titlepayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Fee payment information"])},
      "infopayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immediately make a Application Fee payment in the amount above to be able to continue the admission process. If you have questions or problems"])},
      "contactpayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact the admissions team"])},
      "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here"])},
      "paynow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Now"])},
      "titlebilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BILLING DETAILS"])},
      "titleadditional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADDITIONAL INFORMATION"])},
      "yourorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YOUR ORDER"])},
      "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRODUCT"])},
      "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SUBTOTAL"])},
      "allsupportedpay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Supported Payment"])},
      "acceptall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept all various supported payment methods. Choose your preferred payment on the next page. Secure payment via Midtrans."])},
      "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your personal data will be used to process your order, support your experience throughout this website, and for other purposes describe in our"])},
      "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privacy policy"])},
      "stage2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage 2"])},
      "stage3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage 3"])},
      "stage4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage 4"])},
      "stage5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage 5"])},
      "stage6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage 6"])},
      "stage7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage 7"])},
      "nextstage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next Stage"])},
      "pac_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAC Payment"])},
      "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration"])},
      "adminselect1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration Selection 1"])},
      "admissiontest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admission Test"])},
      "selectionresult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selection Process"])},
      "adminselect2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration Selection 2"])},
      "adminkeuangan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Administration"])},
      "faqdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequently Asked Questions"])},
      "definitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definitive"])},
      "application_form_waiting_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application on review progress"])},
      "application_form_waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application still under review, please wait or contact us to get more information"])},
      "application_form_rejected_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application is Rejected"])},
      "application_form_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your application form is reject, please re-submit your application to be review again, here is why your application rejected :"])},
      "Formulir Evaluasi Diri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self Evaluation Form"])}
    },
    "vacancy": {
      "academic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academics"])},
      "active_recruiting_until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Recruiting Until"])},
      "applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applied"])},
      "apply_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Filter"])},
      "apply_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Job"])},
      "apply_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Now"])},
      "bachelor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bachelor"])},
      "choose_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select City"])},
      "choose_province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Province"])},
      "choose_your_document_for_this_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your document for this application"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "company_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Size"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
      "cv_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CV First"])},
      "diploma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diploma"])},
      "doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor"])},
      "dont_include_cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dont Include CV"])},
      "estimated_sallary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Est. Salary"])},
      "explore_available_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browse Jobs & Apply Online"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seize new career opportunities in your field."])},
      "facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilities"])},
      "farthest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farthest"])},
      "founded_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Founded In"])},
      "freelance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelance"])},
      "fulltime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fulltime"])},
      "hybrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hybrid"])},
      "industry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industry"])},
      "internship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internship"])},
      "insert_your_cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Your CV"])},
      "delete_your_cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Your CV"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "job_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Description"])},
      "job_requirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Requirement"])},
      "latest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest"])},
      "magister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magister"])},
      "message_optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message(Optional)"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "no_vacancy_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Vacancy Found"])},
      "not_specified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Specified"])},
      "onsite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onsite"])},
      "parttime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parttime"])},
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
      "related_vacancies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related Vacancy"])},
      "remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote"])},
      "sallary_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary Range"])},
      "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort"])},
      "submit_aplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit Application"])},
      "till": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["till"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
      "unreal_works_made_by_petranesian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unreal works made by Petranesians"])},
      "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Url"])},
      "upload_new_cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload New CV"])},
      "submit_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Job"])},
      "vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Field"])},
      "visit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit Profile"])},
      "vacancy_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacancy System"])},
      "vacancy_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacancy Type"])},
      "view_company_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Company Profile"])},
      "visit_website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit Website"])}
    },
    "billing": {
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "postalcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Code"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
      "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes (Optional)"])},
      "placeholdernotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])}
    }
  },
  "panel": {
    "product": {
      "add_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Product"])},
      "alumni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alumni"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
      "change_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Product"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue?"])},
      "create_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Product"])},
      "edit_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Product"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
      "how_to_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How To Order"])},
      "insert_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Category"])},
      "insert_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Contact"])},
      "insert_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Location"])},
      "insert_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Product Price"])},
      "insert_product_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Product Image. Max file size is 1 MB and format .jpg & .png"])},
      "insert_product_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert product location"])},
      "insert_product_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Product Name"])},
      "insert_product_thumbnail_image_to_display_in_the_catalog_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert product thumbnail image to display in the catalog product"])},
      "insert_thumbnails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Thumbnails"])},
      "insert_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Photo"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
      "no_product_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Product Added"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
      "product_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Description"])},
      "product_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Name"])},
      "related_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related Product"])},
      "search_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Image"])},
      "you_will_delete_this_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will delete this product "])}
    },
    "vacancy": {
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["active"])},
      "application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apllication"])},
      "application_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Detail"])},
      "bachelor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bachelor"])},
      "choose_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select City"])},
      "choose_prody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Study Program"])},
      "choose_province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Province"])},
      "choose_vacancy_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Vacancy Position"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue?"])},
      "create_vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Vacancy"])},
      "declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declined"])},
      "detail_applicant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Applicant"])},
      "diploma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diploma"])},
      "doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor"])},
      "edit_vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Vacancy"])},
      "expired_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired Date"])},
      "facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilities"])},
      "final_salary_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Salary Range"])},
      "foreword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foreword"])},
      "freelance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelance"])},
      "fulltime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fulltime"])},
      "hybrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hybrid"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inactive"])},
      "insert_final_salary_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Final Salary Range"])},
      "insert_reason_for_acceptance_rejection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert reason for acceptance/rejection"])},
      "insert_starting_salary_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Starting Salary Range"])},
      "internship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internship"])},
      "job_applicant_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Applicants List"])},
      "job_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Description"])},
      "job_requirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Requirement"])},
      "list_of_applicants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Of Applicants"])},
      "list_vacancies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Vacancies"])},
      "magister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magister"])},
      "no_applicants_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Applicants Yet"])},
      "no_vacancy_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Vacancy Found"])},
      "onsite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onsite"])},
      "parttime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Part-time"])},
      "prody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Program"])},
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
      "reason_for_acceptance_rejection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for acceptance/rejection"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])},
      "remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote"])},
      "required_skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required Skill"])},
      "sallary_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary Range"])},
      "seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seen"])},
      "starting_salary_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starting Salary Range"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
      "totals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total(s)"])},
      "vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacancy"])},
      "vacancy_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacancy List"])},
      "vacancy_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacancy Name"])},
      "vacancy_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacancy Position"])},
      "vacancy_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacancy Status"])},
      "vacancy_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacancy System"])},
      "vacancy_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacancy Type"])},
      "visit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit Profile"])},
      "waiting_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting For Confirmation"])},
      "you_will_approve_this_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will approve this application"])},
      "you_will_delete_this_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will delete this application"])},
      "you_will_reject_this_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will reject this application"])}
    }
  },
  "profile": {
    "open_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Sidebar"])},
    "open_profile_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Profile Menu"])},
    "company": {
      "menu": {
        "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Perusahaan"])},
        "your_vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Vacancy"])},
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
      },
      "form_profile": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Profile"])},
        "upload_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Photo"])},
        "choose_other_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Other Photo"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter company name"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter company Email"])},
        "telp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Telephone"])},
        "telp_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter company phone number"])},
        "since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Since"])},
        "since_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the year of establishment"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "password_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Password"])},
        "password_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Confirmation"])},
        "password_confirmation_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Password Confirmation"])},
        "password_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty the password and password confirmation field otherwise want to change the password!"])},
        "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
        "bussines_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bussiness Field"])},
        "bussines_fields_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose bussiness field"])},
        "bussiness_scope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bussiness Scope"])},
        "bussiness_scope_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose bussiness scope"])},
        "bussiness_scope_item": {
          "local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local"])},
          "national": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["National"])},
          "multinational": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multinational / International"])}
        },
        "total_employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Employee"])},
        "total_employee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter total employee"])},
        "work_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Days per Week"])},
        "work_days_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter work days per week"])},
        "work_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Working Hours per Day"])},
        "work_hours_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter total working hours per day"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Company"])},
        "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
        "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
        "province_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Province"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
        "city_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose City"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Address"])},
        "address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter full address"])},
        "contact_person_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Person"])},
        "contact_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "contact_person_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Personal Contact Name"])},
        "contact_person_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
        "contact_person_position_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Personal Contact Position"])},
        "contact_person_telp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
        "contact_person_telp_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Personal Contact Phone Number"])},
        "contact_person_hp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handphone Number"])},
        "contact_person_hp_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Personal Contact Handphone Number"])},
        "social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Media"])},
        "facebook_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Facebook ID"])},
        "twitter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Twitter ID"])},
        "instagram_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Instagram ID"])},
        "linkedin_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter LinkedIn ID"])},
        "tiktok_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Tiktok ID"])},
        "youtube_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Youtube ID"])},
        "save_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Profile"])}
      },
      "company_vacancy": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Vacancies"])},
        "add_vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Vacancy"])},
        "table_header": {
          "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacancy Position"])},
          "work_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work System"])},
          "vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications"])},
          "range_salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Range Salary"])},
          "expired_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Until"])},
          "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])}
        },
        "table_info": {
          "count_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application(s)"])},
          "status_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
          "status_non_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non Active"])},
          "tooltip_see_vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See Vacancy"])},
          "tooltip_edit_vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Vacancy"])},
          "tooltip_delete_vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Vacancy"])}
        },
        "form": {
          "title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Vacancy"])},
          "title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Vacancy"])},
          "work_type_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type Job"])},
          "work_type": {
            "fulltime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fulltime"])},
            "parttime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parttime"])},
            "internship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internship"])},
            "freelance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelance"])}
          },
          "level_education_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education Level"])},
          "level_education": {
            "diploma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diploma"])},
            "sarjana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bachelor"])},
            "magister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master"])},
            "doktor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor"])}
          },
          "program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program"])},
          "program_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Program"])},
          "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Position"])},
          "position_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Job Position"])},
          "salary_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Range Salary Start"])},
          "salary_start_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Range Salary Start"])},
          "salary_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Range Salary End"])},
          "salary_end_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Range Salary End"])},
          "expired_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Recruiting Until"])},
          "system_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job System"])},
          "system": {
            "onsite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onsite"])},
            "remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote"])},
            "hybrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hybrid"])}
          },
          "is_active_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status Vacancy"])},
          "is_active": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non Active"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])}
          ],
          "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
          "province_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Province"])},
          "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
          "city_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose City"])},
          "required_skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required Skill(s)"])},
          "job_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Description"])},
          "requirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requirement"])},
          "facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilities"])},
          "save_vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Vacancy"])},
          "is_social_post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want this vacancy to be uploaded on instagram (at)petracareercenter.job?"])},
          "file_social_post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to upload more than one file, please put them in a folder then compress them with *zip or *rar format 'Posters are recommended in *png file format and square shape with a resolution of 1080x1080.'"])},
          "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])}
        }
      },
      "company_application": {
        "modal_title_step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Applications"])},
        "modal_title_step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Application"])},
        "step1_list": {
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total(s)"])},
          "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Applicants yet"])},
          "status": {
            "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
            "seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seen"])},
            "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved"])},
            "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])}
          },
          "view_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Application"])},
          "view_applicant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Applicant"])},
          "delete_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Applicant"])}
        },
        "step2_detail": {
          "status": {
            "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
            "seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seen"])},
            "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved"])},
            "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])}
          },
          "level_education": {
            "diploma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diploma"])},
            "sarjana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bachelor"])},
            "magister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master"])},
            "doktor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor"])}
          },
          "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for acceptance/rejection"])},
          "tooltip_approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve Applicant"])},
          "tooltip_reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject Applicant"])},
          "tooltip_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Applicant"])},
          "foreword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foreword"])},
          "status_swal": {
            "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will receive application from "])},
            "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will rejecte application from "])}
          },
          "text_swal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue?"])},
          "reason_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the reason for acceptance/rejection"])},
          "delete_swal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will delete application from "])},
          "no_cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Cv Submitted"])}
        }
      }
    },
    "menu": {
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
    },
    "card": {
      "digital": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petranesian Alumni Card"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get yours today!"])},
        "view_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Card"])},
        "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn More"])}
      },
      "tracer_study": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracer Study"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are happy to know your feedback."])},
        "start_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Now"])}
      },
      "marchandise": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petranesian Merchandise"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop official merchandise from Petranesian Shop."])},
        "shop_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop Now"])}
      }
    },
    "form_profile": {
      "secondary_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary Email"])},
      "credential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Information"])},
      "update_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "citizenship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Citizenship"])},
      "placeholdercitizenship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select citizenship"])},
      "small_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(example: 91.25)"])},
      "xthgrade1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade X Sem. 1"])},
      "xthgrade2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade X Sem. 2"])},
      "xithgrade1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade XI Sem. 1"])},
      "placeholderxthgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the average value"])},
      "religion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Religion"])},
      "placeholderreligion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select religion"])},
      "work_status_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Work Status"])},
      "work_status_options": {
        "employed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employed"])},
        "unemployed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open To Work"])}
      },
      "placeofbirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place of birth"])},
      "placeholderplaceofbirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter place of birth"])},
      "placeholdernik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter NIK"])},
      "highschoolorigin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High School Origin"])},
      "graduation_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graduation Year"])},
      "placeholderhighschoolorigin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter high school name"])},
      "open_for_company_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Open For Company"])},
      "open_for_company_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile visibility settings make alumni data details visible to the company"])},
      "open_for_company_options": {
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
      },
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Address"])},
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
      "province_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Province"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "city_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose City"])},
      "university_detail_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["University Detail"])},
      "noreg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Number"])},
      "student_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Identification Number"])},
      "generation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year of Entry"])},
      "generation_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Generation Year"])},
      "regpath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Path"])},
      "profile_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
      "upload_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Photo"])},
      "choose_other_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Other Photo"])},
      "fullname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
      "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Alumni Name"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Email"])},
      "birth_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth Date"])},
      "birth_date_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose birth date"])},
      "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
      "gender_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select gender"])},
      "gender_options": {
        "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
        "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])}
      },
      "currentpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Password"])},
      "currentpassword_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Current Password"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
      "password_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter New Password"])},
      "password_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password Confirmation"])},
      "password_confirmation_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter New Password Confirmation"])},
      "password_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty the password and password confirmation field otherwise want to change the password!"])},
      "contact_information_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Information"])},
      "no_telp_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Phone Number"])},
      "no_telp_hp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cell Phone number"])},
      "social_media_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Media"])},
      "facebook_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter ID Facebook"])},
      "twitter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter ID Twitter"])},
      "instagram_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter ID Instagram"])},
      "linkedin_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter ID LinkedIn"])},
      "tiktok_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter ID Tiktok"])},
      "youtube_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter ID Youtube"])},
      "skill_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills"])},
      "skill_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Skill"])},
      "interest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interest"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
      "save_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
    },
    "education_activities": {
      "education_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educational Background"])},
      "add_education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Educational Background"])},
      "remove_education_swal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Will Delete Education History at "])},
      "remove_education_text_swal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue?"])},
      "gpa_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GPA must be in the number 0 - 4"])},
      "modal_education": {
        "gpa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final GPA"])},
        "gpa_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Final GPA"])},
        "program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program"])},
        "program_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Program Name"])},
        "title_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Educational background"])},
        "title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Educational background"])},
        "institution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution Name"])},
        "institution_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Institution Name"])},
        "level_education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level Education"])},
        "level_education_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Educational stage"])},
        "front_degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Front Degree"])},
        "front_degree_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Front Degree"])},
        "back_degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back Degree"])},
        "back_degree_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Back Degree"])},
        "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Year"])},
        "start_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Start Year"])},
        "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Year"])},
        "end_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter End Year"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education Description"])},
        "button_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "button_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])}
      },
      "table_education": {
        "institution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution Name"])},
        "level_education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level Education"])},
        "gpa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GPA"])},
        "program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program"])},
        "front_degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Front Degree"])},
        "back_degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back Degree"])},
        "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
        "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Education History"])}
      },
      "organization_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization Experience"])},
      "table_organization": {
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization"])},
        "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
        "poin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poin"])},
        "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
        "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Organizational History"])}
      },
      "activities_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture Activities"])},
      "table_activities": {
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
        "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
        "poin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poin"])},
        "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
        "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No History of Lecture Activities"])}
      }
    },
    "work_history": {
      "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Experience"])},
      "add_work_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Work Experience"])},
      "remove_education_swal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Will Delete Work Experience at "])},
      "remove_education_text_swal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue?"])},
      "table": {
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
        "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
        "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
        "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["current"])},
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
        "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
        "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Work Experience"])}
      },
      "modal": {
        "title_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Work Experience"])},
        "title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Work Experience"])},
        "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
        "company_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Company Name"])},
        "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
        "position_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Job Position"])},
        "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
        "province_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Province"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
        "city_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose City"])},
        "start_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start at"])},
        "start_at_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Start Date"])},
        "finish_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish at"])},
        "finish_at_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Finish Date"])},
        "check_finish_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm Still Working Here"])},
        "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary"])},
        "salary_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Last Salary"])},
        "salary_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave it blank if you don't want to display salary"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Description"])},
        "button_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "button_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
        "link_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter company link"])}
      }
    },
    "your_business": {
      "tab_agency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agency"])},
      "tab_freelance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelance"])},
      "agency": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
        "link_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter agency link"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agency"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provide detailed information about your business, including the services you offer, your past experience, and notable clients"])},
        "upload_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Photo"])},
        "choose_other_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Other Photo"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agency Name"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Agency Name"])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
        "contact_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Phone Number"])},
        "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
        "province_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Province"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
        "city_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose City"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
        "address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Address"])},
        "scope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Scope"])},
        "scope_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Business Scope"])},
        "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee"])},
        "fee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Fee Agency"])},
        "fee_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave it blank if you don't want to display costs"])},
        "start_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start at"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agency Description"])},
        "button_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Agency Data"])},
        "button_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Agency Data"])},
        "swal_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Will Delete This Agency"])},
        "swal_text_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue?"])}
      },
      "freelance": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
        "link_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan link freelance"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelance"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List your areas of expertise or specialization in freelance work. This can be industry-specific, technologies or tools you are proficient in, or specific skills you possess"])},
        "upload_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Photo"])},
        "choose_other_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Other Photo"])},
        "expertise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expertise"])},
        "expertise_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Expertise"])},
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
        "location_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Location"])},
        "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee"])},
        "fee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Freelance Fee"])},
        "fee_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave it blank if you don't want to display costs"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelance Description"])},
        "button_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Freelance"])},
        "button_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Freelance"])},
        "swal_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Will Delete This Freelance"])},
        "swal_text_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue?"])}
      }
    }
  },
  "global": {
    "level_education": {
      "diploma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diploma"])},
      "sarjana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bachelor"])},
      "magister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master"])},
      "doktor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor"])}
    },
    "bussiness_scope_item": {
      "local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local"])},
      "national": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["National"])},
      "multinational": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multinational / International"])}
    },
    "choose_city_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select province first"])}
  },
  "photo_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check out how to take your portrait here"])},
  "consent_checkbox_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fees that have been paid cannot be refunded or transferred to prospective students or active students for any reason, except if the student fails to graduate from high school."])},
  "profile_full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Your name here will also be written on your Bachelor's diploma letter, so make sure it's in accordance with your birth certificate."])},
  "google_drive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here to activate your Petra email."])},
  "Regular Admission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular Admission"])},
  "Standard admission process including entrance exams, interviews, and document evaluation. Ideal for recent graduates and traditional applicants.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard admission process including entrance exams, interviews, and document evaluation. Ideal for recent graduates and traditional applicants."])},
  "RPL Admission Process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RPL Admission Process"])},
  "Recognition of Prior Learning (RPL) admission pathway for professionals with significant work experience. Tailored assessment process considering your professional background and achievements.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recognition of Prior Learning (RPL) admission pathway for professionals with significant work experience. Tailored assessment process considering your professional background and achievements. This process is suited if you have prior professional background."])},
  "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])}
}