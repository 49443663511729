export default {
  "form_2": {
    "kedokteran_disabilitas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program studi ini diwajibkan tidak memiliki disabilitas dan atau buta warna, silakan mengisi informasi tambahan pada berkas sekolah"])},
    "simulasi_biaya": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simulasi Biaya"])},
    "not_include_contribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum Termasuk Uang Sumbangan Sukarela"])}
  },
  "payment_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembayaran berhasil dibuat! Sedang memuat Virtual Account."])},
  "login_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selamat Datang!"])},
  "login_header_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ini adalah laman PCU Admission kamu"])},
  "login_notice_browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mohon mengakses laman registrasi ini menggunakan Google Chrome / Mozilla Firefox."])},
  "welcome_home_petranesian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kembangkan Karier Anda Hari Ini!"])},
  "login_anda_stay_connected_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akses laman ini untuk cek portal aplikasimu"])},
  "grow_Professionally_advance_your_professional_life_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kembangkan Karier Hari Ini Bangun koneksi dengan Petranesian maupun perusahaan untuk pertumbuhan karier Anda bersama Platform Alumni Petranesian."])},
  "welcome_back_to_PANNEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selamat datang di Platform Alumni Petranesian"])},
  "log_id_access_our_full_features_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mulai perjalanan serumu bersama PCU dengan mengisi kolom-kolom di bawah ini"])},
  "discover_top_talets_from_our_alumni_database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekrut alumni terbaik kami"])},
  "join_passion_to_hire_our_best_graduates_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bergabunglah dengan Platform Alumni Petranesian dan temukan kandidat tepat untuk pertumbuhan perusahaan"])},
  "company_login_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selamat datang di PANNEL"])},
  "company_login_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk ke akun Anda untuk mengakses seluruh fitur PANNEL, termasuk memasang lowongan pekerjaan dan merekrut alumni kami"])},
  "company_register_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekrut Alumni Terbaik Kami "])},
  "company_register_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bergabunglah dengan PANNEL dan temukan kandidat tepat untuk pertumbuhan perusahaan"])},
  "frontend": {
    "header": {
      "whatsuppetra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apa Kabar Petra"])}
    },
    "footer": {
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat"])},
      "contactinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informasi Kontak"])},
      "heading": {
        "admission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendaftaran"])},
        "tentang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentang Petra"])},
        "hubungi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubungi Kami"])},
        "fasilitas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fasilitas"])},
        "fakultas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akademik"])}
      }
    },
    "submenus": {
      "carapendaftaran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cara Pendaftaran"])},
      "beasiswa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beasiswa"])},
      "halamanpac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login PAC"])},
      "informasites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informasi Tes Seleksi"])},
      "infokeuangan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biaya"])},
      "simulasirapor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simulasi Rapor"])},
      "pengumumanpenerimaan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengumuman Penerimaan"])},
      "pascasarjana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasca Sarjana"])},
      "tesminatbakat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuis Minat Bakat"])},
      "mengapapetra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengapa Petra"])},
      "tinggalsurabaya": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinggal di Surabaya"])},
      "campuslife": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keseharian di Kampus"])},
      "facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fasilitas"])},
      "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitas"])},
      "tanggalpenting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Penting"])},
      "beritaacara": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acara"])},
      "alumni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alumni"])},
      "perpus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perpustakaan"])},
      "klinik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klinik"])},
      "konseling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pusat Konseling"])},
      "puskom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pusat Teknologi Informasi"])},
      "careercenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pusat Karier"])},
      "kemahasiswaan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kemahasiswaan"])},
      "internationaloffice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kantor Kerja Sama dan Urusan Internasional"])},
      "happening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kabar Terbaru"])},
      "highlights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorotan"])},
      "pressrelease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kebijakan dan<br>Pengumuman"])},
      "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arsip"])},
      "programinterheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program International"])},
      "pascaheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pascasarjana / Profesi"])},
      "msipil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magister Teknik Sipil"])},
      "marsitek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magister Arsitektur"])},
      "msastra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magister Sastra"])},
      "mteknik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magister Teknik Industri"])},
      "mmanajemen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magister Manajemen"])},
      "doktorsipil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doktor Teknik Sipil"])},
      "pendidikanprofesiarsitek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendidikan Profesi Arsitek"])},
      "pendidikanprofesiinsinyur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendidikan Profesi Insinyur"])},
      "dmu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departemen Mata Kuliah Umum"])}
    }
  },
  "menu": {
    "alumni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alumni"])},
    "tracer_study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studi Pelacakan"])},
    "find_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pekerjaan"])},
    "find_talents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bakat"])},
    "find_talents_submenu": {
      "inside_image": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alumni Petranesian"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tetap terhubung. Perbarui profil Anda untuk mendapatkan hasil maksimal dari PANNEL."])}
      },
      "submenu_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alumni Petranesian"])},
      "submenu_list": {
        "alumni_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Layanan Alumni"])},
        "alumni_business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bisnis Alumni"])},
        "alumni_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produk Alumni"])}
      }
    },
    "alumni_relations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubungan Alumni"])},
    "alumni_relations_submenu": {
      "give_back": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give Back Program"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tetap terhubung. Perbarui profil Anda untuk mendapatkan hasil maksimal dari PANNEL."])}
      },
      "alumni_stories": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerita Alumni"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join PANNEL to hire our best graduates to boost your company growth."])}
      }
    },
    "merchandise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchandise"])},
    "digital-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petranesian Alumni Card"])},
    "newsinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berita & Informasi"])},
    "sign_in_sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk / Daftar"])},
    "sign_in_sign_up_submenu": {
      "alumni": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untuk Alumni"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tetap terhubung. Perbarui profil Anda untuk mendapatkan hasil maksimal dari PANNEL."])}
      },
      "company": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untuk Perusahaan"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bergabunglah dengan PANNEL untuk merekrut lulusan terbaik kami dalam meningkatkan pertumbuhan perusahaan Anda."])}
      }
    },
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "prospectivestudentdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Calon Mahasiswa"])},
    "admissionprocess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proses Admisi"])},
    "schoolfiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berkas Sekolah"])},
    "averagereport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nilai Rapor Rata-rata"])},
    "studyprogram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilihan Program"])},
    "sign_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keluar"])},
    "mobile": {
      "view_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat Kartu"])},
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akun"])},
      "account_submenu": {
        "view_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat Kartu"])},
        "edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah"])},
        "view_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat Profil"])},
        "education_activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendidikan & Aktifitas"])},
        "work_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengalaman Kerja"])},
        "your_business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bisnis Anda"])},
        "your_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produk Anda"])},
        "applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lamaran"])},
        "bookmarked_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pekerjaan ditandai"])},
        "item_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesanan Anda"])},
        "your_vacancies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lamaran Anda"])}
      }
    }
  },
  "tracerstudy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berikan Umpan Balik agar Kami Lebih Baik Lagi"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hanya dalam 10 menit, Anda dapat membantu kami mempertahankan akreditasi hingga bertahun-tahun "])}
  },
  "logout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda akan keluar dari aplikasi"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanjut?"])}
  },
  "form1": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah Anda yakin untuk mengirimkan formulir ini?"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Data yang sudah diinput tidak dapat diubah. (kecuali: WA, Line, Instagram)"])}
  },
  "forgot_password": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password"])},
    "subtitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silahkan masukan email anda, dan kode OTP akan di kirim melalui email."])},
    "subtitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silahkan masukkan kode OTP yang telah dikirimkan melalui email."])},
    "subtitle3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan kata sandi baru untuk akun anda"])},
    "trouble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akun anda bermasalah? Hubungi kami "])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selanjutnya"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kembali"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan"])},
    "step1": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
    },
    "step2": {
      "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP Code"])},
      "placeholderotp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan kode OTP"])}
    },
    "step3": {
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata sandi"])},
      "password_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi kata sandi"])},
      "placeholderpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan kata sandi"])},
      "placeholdercpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan konfirmasi kata sandi"])}
    },
    "success": {
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata sandi berhasil di ubah."])}
    }
  },
  "login": {
    "placeholderemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan email"])},
    "placeholderpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan kata sandi"])},
    "placeholdername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan nama lengkap"])},
    "fullname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Lengkap"])},
    "termofconditionhead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dengan membuat akun, Anda menyetujui"])},
    "termofconditionbody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syarat dan Ketentuan"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat"])},
    "choose_alumni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Alumni"])},
    "choose_alumni_based_on_lecture_period_and_major_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih alumni bedasarkan periode kuliah dan jurusan terlebih dahulu!!!"])},
    "choose_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Kota"])},
    "choose_prody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Prodi"])},
    "choose_province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Provinsi"])},
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Perusahaan"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanjutkan?"])},
    "data_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Tidak Ditemukan, Mohon Coba Lagi."])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perempuan"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Kelamin"])},
    "birth_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Lahir"])},
    "birth_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempat Lahir"])},
    "graduation_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahun Lulus"])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disini"])},
    "insert_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Alamat"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laki-laki"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selanjutnya"])},
    "nim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NIM"])},
    "forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lupa Password?"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata Sandi"])},
    "password_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi Password"])},
    "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor Telepon"])},
    "please_insert_a_name_of_at_least_3_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mohon masukan nama minimal 3 karakter"])},
    "sign_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk"])},
    "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daftar"])},
    "trouble_register_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bermasalah saat mendaftar? Hubungi kita"])},
    "trouble_signin_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bermasalah saat masuk? Hubungi kita"])},
    "wait_for_a_moment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mohon tunggu beberapa saat"])},
    "year_of_entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahun Masuk"])},
    "you_cant_sign_in_with_admin_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamu tidak bisa masuk dengan akun admin"])},
    "you_will_do_the_registration_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamu akan melanjutkan proses registrasi"])},
    "company": {
      "register_part1": {
        "error_messages": {
          "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama perusahaan wajib diisi"])},
          "bussiness_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sektor bisnis wajib diisi"])},
          "bussiness_scope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingkup bisnis wajib diisi"])},
          "telp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor telepon kantor wajib diisi"])},
          "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kota dan provinsi wajib diisi"])},
          "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat wajib diisi"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi wajib diisi"])}
        },
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Perusahaan *"])},
        "id_mh_bussines_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Sektor Bisnis *"])},
        "bussiness_scope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Lingkup Bisnis *"])},
        "bussiness_scope_item": {
          "local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokal"])},
          "national": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nasional"])},
          "multinational": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multinasional / Internasional"])}
        },
        "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
        "telp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor Telepon Kantor *"])},
        "id_mh_province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Provinsi *"])},
        "id_mh_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Kab/Kota *"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat *"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Logo"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi Perusahaan *"])}
      },
      "register_part2": {
        "contact_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontak Person *"])},
        "contact_person_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jabatan Kontak Person *"])},
        "contact_person_telp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telepon Kontak Person *"])}
      },
      "register_part3": {
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email *"])},
        "email_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gunakan email resmi perusahaan dan bukan email personal"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password *"])},
        "password_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi Password *"])},
        "password_confirm_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi password tidak cocok"])}
      },
      "register_success": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berhasil melakukan pendaftaran, <br />Silahkan tunggu proses persetujuan dari Admin."])}
      }
    }
  },
  "main": {
    "alumni": {
      "alumni_profile_is_not_open_to_companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile alumni tidak terbuka untuk perusahaan!!"])},
      "work_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status Pekerjaan"])},
      "work_status_options": {
        "employed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sedang Bekerja"])},
        "unemployed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terbuka Untuk Bekerja"])}
      },
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentang"])},
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diterima"])},
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktifitas"])},
      "activity_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riwayat Aktifitas"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umur"])},
      "agency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agensi"])},
      "all_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua Status"])},
      "application_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riwayat Lamaran"])},
      "apply_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terapkan Filter"])},
      "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diterima"])},
      "bookmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookmark Pekerjaan"])},
      "bookmarked_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berhasil Menyimpan Bookmark Pekerjaan"])},
      "choose_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih kota"])},
      "choose_city_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih provinsi terlebih dahulu"])},
      "choose_province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih provinsi"])},
      "choose_prody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Program"])},
      "choose_skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Kemampuan"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kota"])},
      "current_sallary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaji Saat Ini"])},
      "view_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat Profil"])},
      "edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit profil"])},
      "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendidikan"])},
      "explore_petranesian_top_talents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelajahi Talenta Terbaik Petranesian"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mulai dari IT, industri kreatif, keuangan, hingga teknik, temukan talenta terbaik bagi kebutuhan organisasi Anda dari database lulusan-lulusan unggulan kami."])},
      "farthest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terlama"])},
      "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perempuan"])},
      "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dari"])},
      "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Kelamin"])},
      "get_yours_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dapatkan milikmu sekarang!"])},
      "graduation_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahun Lulus"])},
      "select_graduation_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Tahun Lulus"])},
      "prioritize_unemployee_alumni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioritaskan alumni yang belum bekerja"])},
      "last_education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendidikan Terakhir"])},
      "latest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tebaru"])},
      "list_alumni_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daftar Alumni Produk"])},
      "load_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muat Lebih Banyak"])},
      "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laki-laki"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
      "no_activity_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak Ada Riwayat Aktifitas"])},
      "no_alumni_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak Ada Alumni Ditemukan :("])},
      "no_education_history_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum Ada Riwayat Pendidikan Ditambahkan"])},
      "gpa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IPK"])},
      "no_front_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak Ada Gelar Depan"])},
      "no_organizational_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak Ada Pengalaman Organisasi"])},
      "no_product_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum Ada Produk Ditambahkan"])},
      "no_title_behind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIdak Ada Gelar Belakang"])},
      "no_work_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum Ada Pengalaman Kerja"])},
      "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekarang"])},
      "open_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buka Kartu"])},
      "organizational": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisasi"])},
      "organizational_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengalaman Organisasi"])},
      "petranesian_digital_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartu Alumni Petranesian"])},
      "point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poin"])},
      "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posisi"])},
      "professional_skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kemampuan Profesional"])},
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provinsi"])},
      "prody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodi"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditolak"])},
      "seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dilihat"])},
      "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menampilkan"])},
      "show_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tampilkan Filter"])},
      "since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berdiri Sejak"])},
      "skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kemampuan"])},
      "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urutkan"])},
      "start_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mulai sekarang"])},
      "there_are_no_bookmark_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum ada Bookmark pekerjaan. Ayo melamar pekerjaan lebih rajin :-)"])},
      "there_are_no_job_applications_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum ada aplikasi pekerjaan. Ayo melamar pekerjaan lebih rajin :-)"])},
      "till": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s/d"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe"])},
      "unreal_works_made_by_petranesian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karya tidak nyata yang dibuat oleh Petranesians"])},
      "vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lowongan Pekerjaan"])},
      "waiting_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menunggu Konfirmasi"])},
      "we_are_happy_to_know_your_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kami senang mendapatkan umpan balik darimu"])},
      "work_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengalaman Kerja"])}
    },
    "company": {
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentang"])},
      "apply_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terapkan Filter"])},
      "choose_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih kota"])},
      "choose_province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Provinsi"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kota"])},
      "company_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukuran Perusahaan"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontak"])},
      "edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit profil"])},
      "my_vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelola Lowongan Pekerjaan"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "find_top_company_around_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari Perusahan Terbaik Disekitarmu"])},
      "founded_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Didirikan Sejak"])},
      "hiring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merekrut"])},
      "industry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industri"])},
      "is_hiring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sedang Merekrut"])},
      "job_vacancies_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lowongan pekerjaan di"])},
      "latest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tebaru"])},
      "looking_for_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mencari Pekerjaan? disini tempat terbaik mendapatkannya"])},
      "more_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lebih Banyak Filter"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
      "no_company_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak Ada Hasil Perusahaan"])},
      "no_vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak Ada Lowongan Pekerjaan"])},
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provinsi"])},
      "show_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tampilkan Filter"])},
      "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urutkan"])},
      "till": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s/d"])},
      "visit_website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunjungi Situs Web"])}
    },
    "stage": {
      "do_you_want_to_continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah kamu ingin melanjutkan?"])},
      "select_muts_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Ukuran Topi"])},
      "muts_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukuran Topi"])},
      "select_jacket_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Ukuran Jaket"])},
      "jacket_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukuran Jaket"])},
      "print_definitive_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cetak Berkas Definitif"])},
      "additional_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informasi Tambahan"])},
      "login_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informasi Login Portal Mahasiswa"])},
      "principal_registration_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NRP"])},
      "selection_exam_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Ujian Seleksi"])},
      "financial_admisitration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrasi Keuangan"])},
      "detail_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Biaya"])},
      "semester_payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembayaran per-Semester"])},
      "course_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembayaran Couse"])},
      "total_first_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Pembayaran Pertama"])},
      "select_voucher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih atau Masukan kode voucher"])},
      "can_pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembayaran dapat dilakukan "])},
      "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disini"])},
      "upload_statement_letter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Surat Pernyataan (* Harap mengunggah dokumen asli)"])},
      "upload_birth_certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Akta Kelahiran (* Harap mengunggah dokumen asli)"])},
      "upload_family_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload KK (* Harap mengunggah dokumen asli)"])},
      "company_hp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Telp Kantor"])},
      "company_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Kantor"])},
      "ward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelurahan"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kota"])},
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provinsi"])},
      "home_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Rumah (Max 100 Chars)"])},
      "parent_hp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Telp Orang tua"])},
      "parent_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Orangtua"])},
      "mother_citizenship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kewarganegaraan Ibu"])},
      "mother_religion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agama Ibu"])},
      "mother_education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendidikan Ibu"])},
      "mother_occupation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pekerjaan Ibu"])},
      "mother_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Ibu"])},
      "father_citizenship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kewarganegaraan Ayah"])},
      "father_religion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agama Ayah"])},
      "father_education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendidikan Ayah"])},
      "father_occupation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pekerjaan Ayah"])},
      "father_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Ayah"])},
      "blood_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Golongan Darah"])},
      "order_come": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anak ke"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
      "download_template_surat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download template surat pernyataan disini"])},
      "family_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Keluarga"])},
      "apply_other_study_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Program Studi Lain"])},
      "change_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Program Studi"])},
      "reject_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tolak Penawaran"])},
      "accept_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terima Penawaran"])},
      "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periode"])},
      "track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jalur"])},
      "study_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program Studi"])},
      "failed_verbiage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda dinyatakan tidak lolos pada program studi:"])},
      "congratulation_verbiage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selamat, Anda dinyatakan lolos pada program studi:"])},
      "general_path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jalur Umum"])},
      "achievement_path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jalur Prestasi"])},
      "registration_path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jalur Pendaftaran"])},
      "school_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asal Sekolah"])},
      "register_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Pendaftaran"])},
      "verbiage4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tim admisi sedang melakukan proses verifikasi dan evaluasi aplikasi Anda. Akses laman secara berkala untuk mendapatkan update terbaru"])},
      "application_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengajuan terkirim"])},
      "selection_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proses Seleksi"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selesai"])},
      "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menit"])},
      "regisvalidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validasi Pendaftaran"])},
      "schollorigin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asal Sekolah"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelas"])},
      "placeholdername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan nama lengkap"])},
      "placeholderemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan email"])},
      "fullname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Lengkap (Sesuai Akta Kelahiran)"])},
      "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kabupaten/Kota Asal Sekolah"])},
      "placeholderdistrict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih kab/kota asal sekolah"])},
      "searchdistrict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ketik untuk mencari kabupaten/kota asal sekolah"])},
      "searchcountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ketik untuk mencari negara"])},
      "searchprovince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ketik untuk mencari provinsi"])},
      "searchcity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ketik untuk mencari kota"])},
      "searchreligion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ketik untuk mencari agama"])},
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asal Sekolah"])},
      "placeholderschool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih nama sekolah"])},
      "searchschool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ketik untuk mencari nama sekolah"])},
      "major": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jurusan"])},
      "placeholdermajor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih jurusan"])},
      "yeargrad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahun Lulus"])},
      "promo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode Undangan (jika ada)"])},
      "placeholderpromo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan kode undangan"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
      "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terapkan"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negara"])},
      "placeholdercountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih negara"])},
      "addressktp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Sesuai KTP / KK"])},
      "placeholderaddressktp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Alamat sesuai KTP / KK"])},
      "addresspaspor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Sesuai Paspor"])},
      "placeholderaddresspaspor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Alamat sesuai Paspor"])},
      "nopassport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor Paspor"])},
      "homeaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Tinggal"])},
      "placeholderhomeaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat"])},
      "subdistrict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kecamatan"])},
      "placeholdersubdistrict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan kecamatan"])},
      "urbanvillage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelurahan"])},
      "placeholderurbanvillage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan kelurahan"])},
      "postalcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode Pos"])},
      "placeholderpostalcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan kode pos"])},
      "connectingaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Domisili saat ini (kos / rumah bila ada)"])},
      "placeholderconnectingaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan alamat domisili saat ini"])},
      "addressbackup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["isikan alamat yang ada di Surabaya dan sekitarnya"])},
      "gereja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Lengkap Gereja"])},
      "placeholdergereja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan gereja"])},
      "gerejanote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["diisi bagi yang beragama Kristen dan Katolik"])},
      "nisn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor Induk Siswa Nasional (NISN)"])},
      "placeholdernisn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan NISN"])},
      "curriculum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurikulum"])},
      "placeholdercurriculum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih kurikulum"])},
      "uploadreport1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unggah Rapor X Sem. 1"])},
      "successupload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berhasil Diunggah: "])},
      "report1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapor X Sem. 1"])},
      "uploadreport2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unggah Rapor X Sem. 2"])},
      "report2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapor X Sem. 2"])},
      "uploadreport3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unggah Rapor XI Sem. 1"])},
      "uploadreport4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unggah Report XI Sem. 2"])},
      "report4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapor XI Sem. 1"])},
      "uploadreport5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unggah Report XII Sem. 1"])},
      "report5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapor XII Sem. 1"])},
      "report3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapor XI Sem. 1"])},
      "testschedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jadwal Test"])},
      "placeholdertestschedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih jadwal test"])},
      "choicestudyprogram1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilihan Program Studi 1"])},
      "placeholderchoicestudyprogram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih program studi"])},
      "studytrackoptions1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilihan Track Studi 1"])},
      "placeholderstudytrackoption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih track studi"])},
      "choicestudyprogram2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilihan Program Studi 2"])},
      "studytrackoptions2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilihan Track Studi 2"])},
      "uploadnotcolorblind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unggah Surat Keterangan Tidak Buta Warna"])},
      "notcolorblind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surat KeteranganTidak Buta Warna"])},
      "difabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Kebutuhan Khusus (Difabel)"])},
      "infoscholarship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik disini untuk mendapatkan informasi seputar beasiswa (tidak berlaku untuk jurusan kedokteran)"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batalkan"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanjutkan"])},
      "Link To Check NISN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link Untuk Pengecekan NISN"])}
    },
    "talent": {
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentang"])},
      "agency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agensi"])},
      "apply_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terapkan Filter"])},
      "choose_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Kota"])},
      "choose_province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Provinsi"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kota"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontak"])},
      "contact_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informasi Kontak"])},
      "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bayaran"])},
      "find_top_service_around_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temukan Barang & Jasa Karya Alumni UK Petra"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dari industri kuliner, busana, hingga teknologi, telusuri produk-produk alumni Petranesian di sini"])},
      "freelance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pekerja Lepas"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokasi"])},
      "looking_for_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dari industri kuliner, busana, hingga teknologi, telusuri produk-produk alumni Petranesian di sini."])},
      "no_service_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak Ada Layanan Ditemukan"])},
      "profile_alumni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil Alumni"])},
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provinsi"])},
      "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Layanan"])},
      "show_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tampilkan Filter"])}
    },
    "home": {
      "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selamat datang"])},
      "track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lacak Penerimaan"])},
      "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanya Jawab"])},
      "stage1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahap 1"])},
      "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembayaran Application Fee"])},
      "textpayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segera lakukan pembayaran registrasi untuk dapat melanjutkan proses admisi, jika sudah membayar mohon refresh halaman dalam 2-3 menit menunggu sistem kami melakukan validasi pembayaran, kamu bisa pergi ke halaman pembayaran dengan klik "])},
      "titlepayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informasi pembayaran Application Fee"])},
      "infopayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segera lakukan pembayaran Application Fee sejumlah di atas untuk dapat melanjutkan proses admisi. Jika ada pertanyaan atau kendala"])},
      "contactpayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hubungi tim admisi"])},
      "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disini"])},
      "paynow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bayar Sekarang"])},
      "titlebilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DETAIL TAGIHAN"])},
      "titleadditional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFORMASI TAMBAHAN"])},
      "yourorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PESANAN ANDA"])},
      "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRODUK"])},
      "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SUBTOTAL"])},
      "allsupportedpay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua Pembayaran yang Didukung"])},
      "acceptall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terima semua berbagai metode pembayaran yang didukung. Pilih pembayaran pilihan Anda di halaman berikutnya. Pembayaran aman melalui Midtrans."])},
      "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data pribadi Anda akan digunakan untuk memproses pesanan Anda, mendukung pengalaman Anda di seluruh situs web ini, dan untuk tujuan lain yang dijelaskan dalam"])},
      "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kebijakan privasi kami"])},
      "stage2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahap 2"])},
      "stage3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahap 3"])},
      "stage4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahap 4"])},
      "stage5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahap 5"])},
      "stage6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahap 6"])},
      "stage7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahap 7"])},
      "nextstage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahap Selanjutnya"])},
      "pac_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembayaran Pac"])},
      "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrasi"])},
      "adminselect1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleksi Administrasi 1"])},
      "admissiontest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tes Penerimaan"])},
      "selectionresult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proses Seleksi"])},
      "adminselect2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleksi Administrasi 2"])},
      "adminkeuangan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrasi Keuangan"])},
      "faqdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pertanyaan yang Sering Diajukan"])},
      "definitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definitif"])},
      "application_form_waiting_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikasi sedang dalam proses"])},
      "application_form_waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form sedang dalam peninjauan, mohon tunggu atau kontak kami untuk informasi lebih lanjut"])},
      "application_form_rejected_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikasi ditolak"])},
      "application_form_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikasi ditolak, mohon submit ulang agar tim kami dapat meninjau kembali, berikut adalah alasan aplikasi ditolak :"])},
      "Formulir Evaluasi Diri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulir Evaluasi Diri"])}
    },
    "vacancy": {
      "academic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akademik"])},
      "active_recruiting_until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif Merekrut Sampai"])},
      "applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telah Dilamar"])},
      "apply_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terapkan Filter"])},
      "apply_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lamar Pekerjaan"])},
      "apply_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lamar Sekarang"])},
      "bachelor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sarjana"])},
      "choose_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Kota"])},
      "choose_province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Provinsi"])},
      "choose_your_document_for_this_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih dokumenmu untuk lamaran ini"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kota"])},
      "company_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukuran Perusahaan"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat"])},
      "cv_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CV Terlebih Dahulu"])},
      "diploma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diploma"])},
      "doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokter"])},
      "dont_include_cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jangan Sertakan CV"])},
      "estimated_sallary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rentang Gaji"])},
      "explore_available_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari Lowongan Pekerjaan & Lamar Secara Online"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raih peluang karir di bidang Anda."])},
      "facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fasilitas"])},
      "farthest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terlama"])},
      "founded_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Didirikan Sejak"])},
      "freelance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pekerja Lepas"])},
      "fulltime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penuh Waktu"])},
      "hybrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campuran"])},
      "industry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industri"])},
      "internship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magang"])},
      "insert_your_cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan CV anda"])},
      "delete_your_cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus CV anda"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus"])},
      "job_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi Pekerjaan"])},
      "job_requirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kebutuhan Pekerjaan"])},
      "latest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tebaru"])},
      "magister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magister"])},
      "message_optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesan(Opsional)"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
      "no_vacancy_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak Ada Lowongan Ditemukan"])},
      "not_specified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak Spesifik"])},
      "onsite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditempat"])},
      "parttime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paruh Waktu"])},
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provinsi"])},
      "related_vacancies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lowongan Yang Bersangkutan"])},
      "remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jarak Jauh"])},
      "sallary_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rentang Gaji"])},
      "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urutkan"])},
      "submit_aplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim Lamaran"])},
      "till": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s/d"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelar"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Url"])},
      "unreal_works_made_by_petranesian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karya tidak nyata yang dibuat oleh Petranesians"])},
      "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unggah"])},
      "upload_new_cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unggah CV Baru"])},
      "submit_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lamar Pekerjaan"])},
      "vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacancy"])},
      "visit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunjungi Profil"])},
      "vacancy_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistem Lowongan Pekerjaan"])},
      "vacancy_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe Lowongan Pekerjaan"])},
      "view_company_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liat Profil Perusahaan"])},
      "visit_website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunjungi Situs Web"])}
    },
    "billing": {
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Depan"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Belakang"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negara"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat"])},
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provinsi"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kota"])},
      "postalcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode Pos"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor Telepon"])},
      "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan (Opsional)"])},
      "placeholdernotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan"])}
    }
  },
  "panel": {
    "product": {
      "add_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Produk"])},
      "alumni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alumni"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
      "change_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Produk"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontak"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanjutkan ?"])},
      "create_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat Produk"])},
      "edit_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Produk"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
      "how_to_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cara Pesan"])},
      "insert_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Kategori"])},
      "insert_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Kontak"])},
      "insert_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Lokasi"])},
      "insert_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Harga Produk"])},
      "insert_product_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan gambar produk. Maksimal ukuran file 1 MB dan format .jpg & .png"])},
      "insert_product_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Lokasi Produk"])},
      "insert_product_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Nama Produk"])},
      "insert_product_thumbnail_image_to_display_in_the_catalog_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan thumbnail untuk ditampilkan di katalog produk"])},
      "insert_thumbnails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Thumbnail"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokasi"])},
      "no_product_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak Ada Produk Ditambahin"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harga"])},
      "product_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi Produk"])},
      "product_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Produk"])},
      "related_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produk Terkait"])},
      "search_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari Gambar"])},
      "you_will_delete_this_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamu akan menghapus produk ini "])}
    },
    "vacancy": {
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diterima"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aksi"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
      "application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lamaran"])},
      "application_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Lamaran"])},
      "bachelor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sarjana"])},
      "choose_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Kota"])},
      "choose_prody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Prodi"])},
      "choose_province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Provinsi"])},
      "choose_vacancy_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih posisi lowongan"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kota"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanjutkan ?"])},
      "create_vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat Lowongan Pekerjaan"])},
      "declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditolak"])},
      "detail_applicant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Pelamar"])},
      "diploma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diploma"])},
      "doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doktor"])},
      "edit_vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Lowongan Pekerjaan"])},
      "expired_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Kadaluarsa"])},
      "facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fasilitas"])},
      "final_salary_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rentang Gaji Akhir"])},
      "foreword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata Pengantar"])},
      "freelance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pekerja Lepas"])},
      "fulltime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penuh Waktu"])},
      "hybrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campuran"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak Aktif"])},
      "insert_final_salary_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan rentang gaji akhir"])},
      "insert_reason_for_acceptance_rejection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan alasan penerimaan/penolakan"])},
      "insert_starting_salary_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan rentang gaji awal"])},
      "internship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magang"])},
      "job_applicant_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Pelamar"])},
      "job_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi Pekerjaan"])},
      "job_requirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kebutuhan Pekerjaan"])},
      "list_of_applicants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Pelamar"])},
      "list_vacancies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Lowongan"])},
      "magister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magister"])},
      "no_applicants_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum Ada Pelamar"])},
      "no_vacancy_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak Ada Lowongan Ditemukan"])},
      "onsite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditempat"])},
      "parttime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paruh Waktu"])},
      "prody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodi"])},
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provinsi"])},
      "reason_for_acceptance_rejection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alasan Penolakan/penerimaan"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditolak"])},
      "remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jarak Jauh"])},
      "required_skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kemampuan Yang Dibutuhkan"])},
      "sallary_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rentang Gaji"])},
      "seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dilihat"])},
      "starting_salary_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rentang Gaji Awal"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelar"])},
      "totals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
      "vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lowongan Pekerjaan"])},
      "vacancy_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Lowongan Pekerjaan"])},
      "vacancy_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Lowongan Pekerjaan"])},
      "vacancy_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posisi Lowongan Pekerjaan"])},
      "vacancy_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status Lowongan"])},
      "vacancy_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistem Pekerjaan"])},
      "vacancy_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe Lowongan Pekerjaan"])},
      "visit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunjungi Profil"])},
      "waiting_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menunggu Konfirmasi"])},
      "you_will_approve_this_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamu akan menerima lamaran pekerjaan ini"])},
      "you_will_delete_this_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamu akan menghapus lamaran pekerjaan ini"])},
      "you_will_reject_this_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamu akan menolak lamaran pekerjaan ini"])}
    }
  },
  "profile": {
    "open_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buka Menu"])},
    "open_profile_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buka Menu Profil"])},
    "company": {
      "menu": {
        "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil Perusahaan"])},
        "your_vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lowongan Anda"])},
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keluar"])}
      },
      "form_profile": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil Perusahaan"])},
        "upload_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unggah Foto"])},
        "choose_other_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Foto Lain"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Perusahaan"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan nama perusahaan"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "telp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Telepon"])},
        "telp_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Nomor Telepon Perusahaan"])},
        "since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahun Berdiri"])},
        "since_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan tahun berdiri perusahaan"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata Sandi"])},
        "password_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Kata Sandi"])},
        "password_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi Kata Sandi"])},
        "password_confirmation_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Konfirmasi Kata Sandi"])},
        "password_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosongkan kolom kata sandi dan konfirmasi kata sandi jika tidak ingin mengubah kata sandi!"])},
        "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentang"])},
        "bussines_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bidang Bisnis"])},
        "bussines_fields_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih bidang bisnis"])},
        "bussiness_scope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sektor Bisnis"])},
        "bussiness_scope_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Sektor Bisnis"])},
        "bussiness_scope_item": {
          "local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local"])},
          "national": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["National"])},
          "multinational": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multinational / International"])}
        },
        "total_employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah Pagawai"])},
        "total_employee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan jumlah pegawai"])},
        "work_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hari kerja per Minggu"])},
        "work_days_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan hari kerja per minggu"])},
        "work_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total jam kerja per Hari"])},
        "work_hours_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan total jam kerja per hari"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentang Perusahaan"])},
        "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi Perusahaan"])},
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokasi"])},
        "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provinsi"])},
        "province_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Provinsi"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kota"])},
        "city_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Kota"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Lengkap"])},
        "address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan alamat lengkap"])},
        "contact_person_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontak Person"])},
        "contact_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
        "contact_person_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan nama Orang yang dapat dihubungi"])},
        "contact_person_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posisi"])},
        "contact_person_position_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan posisi orang yang dapat dihubungi"])},
        "contact_person_telp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor Telepon"])},
        "contact_person_telp_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan nomor telepon orang yang dapat dihubungi"])},
        "contact_person_hp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor HP"])},
        "contact_person_hp_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan nomor HP orang yang dapat dihubungi"])},
        "social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media sosial"])},
        "facebook_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maskan ID Facebook"])},
        "twitter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maskan ID Twitter"])},
        "instagram_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maskan ID Instagram"])},
        "linkedin_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maskan ID LinkedIn"])},
        "tiktok_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maskan ID Tiktok"])},
        "youtube_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maskan ID Youtube"])},
        "save_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan Profil"])}
      },
      "company_vacancy": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lowongan Anda"])},
        "add_vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Lowongan Pekerjaan"])},
        "table_header": {
          "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posisi Lowongan"])},
          "work_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistem Kerja"])},
          "vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lamaran"])},
          "range_salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rentang Gaji"])},
          "expired_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif Hingga"])},
          "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aksi"])}
        },
        "table_info": {
          "count_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lamaran"])},
          "status_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
          "status_non_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non Aktif"])},
          "tooltip_see_vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat Pekerjaan"])},
          "tooltip_edit_vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Pekerjaan"])},
          "tooltip_delete_vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus Pekerjaan"])}
        },
        "form": {
          "title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Pekerjaan"])},
          "title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Pekerjaan"])},
          "work_type_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe Pekerjaan"])},
          "work_type": {
            "fulltime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fulltime"])},
            "parttime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parttime"])},
            "internship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magang"])},
            "freelance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pekerja Lepas"])}
          },
          "level_education_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tingkat Pendidikan"])},
          "level_education": {
            "diploma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diploma"])},
            "sarjana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sarjana"])},
            "magister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magister"])},
            "doktor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doktor"])}
          },
          "program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jurusan"])},
          "program_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Program"])},
          "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posisi Pekerjaan"])},
          "position_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Posisi Pekerjaan"])},
          "salary_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rentang Gaji Awal"])},
          "salary_start_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan rentang gaji awal"])},
          "salary_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rentang Gaji Akhir"])},
          "salary_end_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan rentang gaji akhir"])},
          "expired_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perekrutan Aktif Sampai"])},
          "system_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistem Pekerjaan"])},
          "system": {
            "onsite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onsite"])},
            "remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote"])},
            "hybrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hybrid"])}
          },
          "is_active_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status Lowongan"])},
          "is_active": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak Aktif"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])}
          ],
          "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provinsi"])},
          "province_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Provinsi"])},
          "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kota"])},
          "city_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Kota"])},
          "required_skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required Skill(s)"])},
          "job_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi Pekerjaan"])},
          "requirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persyaratan"])},
          "facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fasilitas"])},
          "save_vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan Lowongan Pekerjaan"])},
          "is_social_post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah Anda menghendaki lowongan ini diunggah di instagram (at)petracareercenter.job?"])},
          "file_social_post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JIka ingin unggah lebih dari satu file, silakan masukkan dalam folder kemudian compress dengan format *zip atau *rar 'Poster disarankan dalam format file *png dan bentuk square dengan resolusi 1080x1080.'"])},
          "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unduh"])}
        }
      },
      "company_application": {
        "modal_title_step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daftar Pelamar"])},
        "modal_title_step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Pelamar"])},
        "step1_list": {
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
          "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum Ada Pelamar"])},
          "status": {
            "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menunggu Konfirmasi"])},
            "seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dilihat"])},
            "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diterima"])},
            "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditolak"])}
          },
          "view_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat Lamaran"])},
          "view_applicant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat Pelamar"])},
          "delete_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus Lamaran"])}
        },
        "step2_detail": {
          "status": {
            "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menunggu Konfirmasi"])},
            "seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dilihat"])},
            "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diterima"])},
            "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditolak"])}
          },
          "level_education": {
            "diploma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diploma"])},
            "sarjana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sarjana"])},
            "magister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magister"])},
            "doktor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doktor"])}
          },
          "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alasan penerimaan/penolakan"])},
          "tooltip_approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terima Lamaran"])},
          "tooltip_reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tolak Lamaran"])},
          "tooltip_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus Lamaran"])},
          "foreword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata Pengantar"])},
          "status_swal": {
            "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda akan menerima lamaran "])},
            "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda akan menolak lamaran "])}
          },
          "text_swal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanjut?"])},
          "reason_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan alasan penerimaan/penolakan"])},
          "delete_swal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda akan menghapus lamaran "])},
          "no_cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak Mengirimkan CV"])}
        }
      }
    },
    "menu": {
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akun"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keluar"])}
    },
    "card": {
      "digital": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartu Alumni Petranesian"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dapatkan milikmu hari ini!"])},
        "view_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat Kartu"])},
        "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelajari Lebih Lanjut"])}
      },
      "tracer_study": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studi Pelacakan"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kami senang mengetahui tanggapan Anda."])},
        "start_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mulai Sekarang"])}
      },
      "marchandise": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchandise Petranesian"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belanja merchandise resmi dari Petranesian Shop."])},
        "shop_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belanja Sekarang"])}
      }
    },
    "form_profile": {
      "secondary_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Sekunder"])},
      "credential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informasi Akun"])},
      "update_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan"])},
      "citizenship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kewarganegaraan"])},
      "placeholdercitizenship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih kewarganegaraan"])},
      "small_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(contoh: 91.25)"])},
      "xthgrade1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelas X Sem. 1"])},
      "xthgrade2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelas X Sem. 2"])},
      "xithgrade1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelas XI Sem. 1"])},
      "placeholderxthgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan nilai rata-rata"])},
      "religion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agama"])},
      "placeholderreligion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih agama"])},
      "work_status_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Status Pekerjaan"])},
      "work_status_options": {
        "employed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekerja"])},
        "unemployed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terbuka Untuk Bekerja"])}
      },
      "placeofbirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempat Lahir"])},
      "placeholderplaceofbirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan tempat lahir"])},
      "placeholdernik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan NIK"])},
      "highschoolorigin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asal SMA"])},
      "placeholderhighschoolorigin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan nama SMA"])},
      "graduation_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahun Lulus"])},
      "open_for_company_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Terbuka Untuk Perusahaan"])},
      "open_for_company_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan visibilitas profil membuat detail data alumni bisa dilihat oleh perusahaan"])},
      "open_for_company_options": {
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terbuka"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tertutup"])}
      },
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat"])},
      "address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Alamat"])},
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provinsi"])},
      "province_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Provinsi"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kota"])},
      "city_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Kota"])},
      "university_detail_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Universitas"])},
      "noreg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor Registrasi"])},
      "student_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Nomor Induk Alumni"])},
      "generation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angkatan"])},
      "generation_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Tahun angkatan"])},
      "regpath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jalur Pendaftaran"])},
      "profile_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
      "upload_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unggah Foto"])},
      "choose_other_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Foto Lain"])},
      "fullname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Lengkap"])},
      "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Nama Alumni"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Email"])},
      "birth_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Lahir"])},
      "birth_date_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih tanggal lahir"])},
      "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Kelamin"])},
      "gender_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih jenis kelamin"])},
      "gender_options": {
        "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laki-laki"])},
        "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perempuan"])}
      },
      "currentpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata Sandi Saat Ini"])},
      "currentpassword_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Kata Sandi Saat Ini"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata Sandi Baru"])},
      "password_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Kata Sandi Baru"])},
      "password_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi Kata Sandi Baru"])},
      "password_confirmation_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Konfirmasi Kata Sandi Baru"])},
      "password_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosongkan kolom password dan konfirmasi password jika tidak ingin mengubah password!"])},
      "contact_information_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informasi Kontak"])},
      "no_telp_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telepon Rumah"])},
      "no_telp_hp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telepon Hp"])},
      "social_media_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media Sosial"])},
      "facebook_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maskan ID Facebook"])},
      "twitter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maskan ID Twitter"])},
      "instagram_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maskan ID Instagram"])},
      "linkedin_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maskan ID LinkedIn"])},
      "tiktok_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maskan ID Tiktok"])},
      "youtube_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maskan ID Youtube"])},
      "skill_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keahlian"])},
      "skill_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Keahlian"])},
      "interest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minat"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentang"])},
      "save_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan"])}
    },
    "education_activities": {
      "education_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riwayat Pendidikan"])},
      "add_education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Riwayat Pendidikan"])},
      "remove_education_swal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda Akan Menghapus Riwayat Pendidikan di "])},
      "remove_education_text_swal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanjut?"])},
      "gpa_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IPK harus berada pada angka 0-4"])},
      "modal_education": {
        "gpa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IPK Terakhir"])},
        "gpa_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan IPK Terakhir"])},
        "program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jurusan Kuliah"])},
        "program_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Jurusan Kuliah"])},
        "title_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Riwayat Pendidikan"])},
        "title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Riwayat Pendidikan"])},
        "institution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Institusi"])},
        "institution_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Nama Institusi"])},
        "level_education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenjang Pendidikan"])},
        "level_education_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Jenjang Pendidikan"])},
        "front_degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelar Depan"])},
        "front_degree_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Gelar Depan"])},
        "back_degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelar Belakang"])},
        "back_degree_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Gelar Belakang"])},
        "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahun Mulai"])},
        "start_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Tahun Mulai"])},
        "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahun Selesai"])},
        "end_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Tahun Selesai"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi Pendidikan"])},
        "button_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan"])},
        "button_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah"])}
      },
      "table_education": {
        "institution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Institusi"])},
        "level_education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenjang Pendidikan"])},
        "gpa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IPK"])},
        "program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jurusan"])},
        "front_degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelar Depan"])},
        "back_degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelar Belakang"])},
        "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periode"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aksi"])},
        "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum Ada Riwayat Pendidikan"])}
      },
      "organization_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengalaman Organisasi"])},
      "table_organization": {
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisasi"])},
        "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periode"])},
        "poin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poin"])},
        "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posisi"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aksi"])},
        "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum Ada Riwayat Organisasi"])}
      },
      "activities_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktifitas Perkuliahan"])},
      "table_activities": {
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitas"])},
        "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periode"])},
        "poin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poin"])},
        "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posisi"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aksi"])},
        "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum Ada Riwayat Aktivitas Kuliah"])}
      }
    },
    "work_history": {
      "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengalaman Kerja"])},
      "add_work_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Pengalaman Kerja"])},
      "remove_education_swal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda Akan Menghapus Pengalaman Kerja di "])},
      "remove_education_text_swal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanjut?"])},
      "table": {
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Perusahaan"])},
        "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posisi"])},
        "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaji"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
        "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekarang"])},
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokasi"])},
        "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periode"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aksi"])},
        "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum Ada Pengalaman Kerja"])}
      },
      "modal": {
        "title_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Riwayat Pekerjaan"])},
        "title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Riwayat Pekerjaan"])},
        "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Perusahaan"])},
        "company_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Nama Perusahaan"])},
        "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posisi"])},
        "position_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Posisi Pekerjaan"])},
        "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provinsi"])},
        "province_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Provinsi"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kota"])},
        "city_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Kota"])},
        "start_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Mulai"])},
        "start_at_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Tanggal Mulai"])},
        "finish_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Selesai"])},
        "finish_at_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Tanggal Selesai"])},
        "check_finish_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saya Masih Bekerja Disini"])},
        "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaji"])},
        "salary_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Gaji Terakhir"])},
        "salary_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosongkan jika tidak ingin menampilkan gaji"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi Pekerjaan"])},
        "button_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan"])},
        "button_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
        "link_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan link perusahaan"])}
      }
    },
    "your_business": {
      "tab_agency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agensi"])},
      "tab_freelance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelance"])},
      "agency": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
        "link_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan link agensi"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agensi"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berikan informasi rinci tentang bisnismu, termasuk layanan yang ditawarkan, serta pengalaman dan klien yang pernah bekerja sama denganmu"])},
        "upload_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unggah Foto"])},
        "choose_other_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Foto Lain"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Agensi"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Nama Agensi"])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor Telepon"])},
        "contact_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Nomor Telepon"])},
        "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provinsi"])},
        "province_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Provinsi"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kota"])},
        "city_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Kota"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat"])},
        "address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Alamat"])},
        "scope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jangkauan"])},
        "scope_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Jangkauan Bisnis"])},
        "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biaya"])},
        "fee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Biaya Agensi"])},
        "fee_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosongkan jika tidak ingin menampilkan biaya"])},
        "start_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berdiri Sejak"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi Agensi"])},
        "button_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Data Agansi"])},
        "button_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus Data Agensi"])},
        "swal_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda Akan Menghapus Agensi ini"])},
        "swal_text_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanjut?"])}
      },
      "freelance": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
        "link_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan link freelance"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelance Pribadi"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantumkan bidang keahlian atau spesialisasimu, seperti industri, teknologi atau alat yang kamu kuasai, atau keterampilan khusus yang kamu miliki"])},
        "upload_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unggah Foto"])},
        "choose_other_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Foto Lain"])},
        "expertise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bidang Keahlian"])},
        "expertise_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Bidang Keahlian"])},
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokasi"])},
        "location_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Lokasi"])},
        "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biaya"])},
        "fee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan Biaya"])},
        "fee_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosongkan jika tidak ingin menampilkan biaya"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi Freelance"])},
        "button_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah Data Freelance"])},
        "button_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus Data Freelance"])},
        "swal_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda Akan Menghapus Freelance ini"])},
        "swal_text_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanjut?"])}
      }
    }
  },
  "global": {
    "level_education": {
      "diploma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diploma"])},
      "sarjana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sarjana"])},
      "magister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magister"])},
      "doktor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doktor"])}
    },
    "bussiness_scope_item": {
      "local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local"])},
      "national": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["National"])},
      "multinational": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multinational / International"])}
    },
    "choose_city_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih provinsi terlebih dahulu"])}
  },
  "photo_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cek ketentuan Pas Foto di sini"])},
  "consent_checkbox_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biaya yang sudah dibayarkan tidak dapat dikembalikan maupun dialihkan ke calon mahasiswa ataupun mahasiswa aktif dengan alasan apa pun kecuali siswa/i tersebut tidak lulus SMA"])},
  "profile_full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Nama yang tercantum di sini adalah nama yang akan tertulis di ijazahmu nanti. Pastikan nama sudah sesuai dengan akta lahir."])},
  "google_drive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik untuk panduan aktivasi email Petra."])},
  "Regular Admission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admisi Reguler"])},
  "Standard admission process including entrance exams, interviews, and document evaluation. Ideal for recent graduates and traditional applicants.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proses admisi standar termasuk ujian masuk, wawancara, dan evaluasi dokumen. Proses ini ideal untuk lulusan baru dan pelamar tradisional."])},
  "RPL Admission Process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admisi Rekognisi Pembelajaran Lampau (RPL)"])},
  "Recognition of Prior Learning (RPL) admission pathway for professionals with significant work experience. Tailored assessment process considering your professional background and achievements.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jalur admisi Petra Rekognisi Pembelajaran Lampau (RPL) untuk profesional dengan pengalaman kerja yang signifikan. Proses penilaian disesuaikan dengan latar belakang dan pencapaian profesional Anda. Proses ini cocok untuk anda yang memiliki pengalaman professional"])},
  "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanjutkan"])}
}